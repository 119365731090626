#root{
    flex-direction: column;
    display: flex;
    min-height: 100vh;
}

body{cursor: none;}

#progress {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
}

.bg {
    stroke: white;
    opacity: 0.4;
}

circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff3;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #EC2028;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }