@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "IBMPlexMono";
  src: url(./fonts/IBMPlexMono-Regular.ttf);
}

body {
  background-color: #1a1a1a;
}
